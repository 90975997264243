const TokenKey = 'Authorization' //access token
const tenantcodeKey = 'tenantcode' //租户编码
const zhanghaocode ='ZhangHaoCode' //账号授权码
const userName='userName'//账号
const userRealName='userRealName'//真名
const UserId='UserId'//用户id
const ROLE ='role'//角色
const expireTimes='30min'//过期时间30分钟
// import {getCurrentInstance} from 'vue'
// const {proxy} = getCurrentInstance()
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
//获取用户角色
export function getRole (tokenKey) {
  if (!tokenKey) {
    tokenKey = ROLE
  }
  return cookies.get(tokenKey)
}
// 设置用户角色
export function setRole (role, tokenKey) {
  if (!tokenKey) {
    tokenKey = ROLE
  }
  return cookies.set(tokenKey, role)
}
//获取用户id
export function getUserId (tokenKey) {
  if (!tokenKey) {
    tokenKey = UserId
  }
  return cookies.get(tokenKey)
}
// 设置用户id
export function setUserId (userid, tokenKey) {
  if (!tokenKey) {
    tokenKey = UserId
  }
  return cookies.set(tokenKey, userid)
}
//清除用户id
export function removeUserId (tokenKey) {
  if (!tokenKey) {
    tokenKey = UserId
  }
  return cookies.remove(tokenKey)
}
//获取真名
export function getRealUsername (tokenKey) {
  if (!tokenKey) {
    tokenKey = userRealName
  }
  return cookies.get(tokenKey) 
  // return sessionStorage.getItem(tokenKey)
}
// 设置真名
export function setRealUsername (username, tokenKey) {
  if (!tokenKey) {
    tokenKey = userRealName
  }
  return cookies.set(tokenKey,username)  
  // return sessionStorage.setItem(tokenKey, username)
}
//清除真名信息
export function removeRealUsername (tokenKey) {
  if (!tokenKey) {
    tokenKey = userRealName
  }
  return cookies.remove(tokenKey)
  // return  sessionStorage.removeItem(tokenKey)
}
//获取账号
export function getUsername (tokenKey) {
  if (!tokenKey) {
    tokenKey = userName
  }
  return cookies.get(tokenKey) 
  // return sessionStorage.getItem(tokenKey)
}
// 设置账号
export function setUsername (username, tokenKey) {
  if (!tokenKey) {
    tokenKey = userName
  }
  return cookies.set(tokenKey,username)  
  // return sessionStorage.setItem(tokenKey, username)
}
//清除账号信息
export function removeUsername (tokenKey) {
  if (!tokenKey) {
    tokenKey = userName
  }
  return cookies.remove(tokenKey)
  // return sessionStorage.removeItem(tokenKey)
}
export function getToken (tokenKey) {
  if (!tokenKey) {
    tokenKey = TokenKey
  }
  return sessionStorage.getItem(tokenKey)
}

export function setToken (token, tokenKey) {
  if (!tokenKey) {
    tokenKey = TokenKey
  }
  return sessionStorage.setItem(tokenKey, token,expireTimes)
}

export function removeToken (tokenKey) {
  if (!tokenKey) {
    tokenKey = TokenKey
  }
  return sessionStorage.removeItem(tokenKey)
}

export function getTenantcode (codekey) {
  if (!codekey) {
    codekey = tenantcodeKey
  }
  return sessionStorage.getItem(codekey)
}

export function setTenantcode (code, codekey) {
  if (!codekey) {
    codekey = tenantcodeKey
  }
  return sessionStorage.setItem(codekey, code,expireTimes)
}

export function removeTenantcode (codekey) {
  if (!codekey) {
    codekey = tenantcodeKey
  }
  return sessionStorage.removeItem(codekey)
}

export function getZhangHaoCode (codekey) {
  if (!codekey) {
    codekey = zhanghaocode
  }
  return sessionStorage.getItem(codekey)
}

export function setZhangHaoCode (code, codekey) {
  if (!codekey) {
    codekey = zhanghaocode
  }
  return sessionStorage.setItem(codekey, code)
}

export function removeZhangHaoCode (codekey) {
  if (!codekey) {
    codekey = zhanghaocode
  }
  return sessionStorage.removeItem(codekey)
}

