const getters = {
  documentClientHeight: state => state.common.documentClientHeight,
  navbarLayoutType: state => state.common.navbarLayoutType,
  sidebarLayoutSkin: state => state.common.sidebarLayoutSkin,
  sidebarFold: state => state.common.sidebarFold,
  menuList: state => state.common.menuList,
  menuActiveName: state => state.common.menuActiveName,
  mainTabs: state => state.common.mainTabs,
  mainTabsActiveName: state => state.common.mainTabsActiveName,
  id: state => state.user.id,
  name: state => state.user.name,
  realname: state => state.user.realname
}
export default getters
