import { createApp } from 'vue'
// import VueCookies from 'vue3-cookies'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueAxios from 'vue-axios'
import axios from '@/utils/httpRequest'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from "element-plus/es/locale/lang/zh-cn"


const app = createApp(App)
app.use(VueAxios,axios)
app.use(store)  
app.use(router)
// app.use(VueCookies)
app.use(ElementPlus,{
  locale:zhCn
})
//  app.config.globalProperties.$cookies=VueCookies;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.mount('#app')
const debounce = (fn, delay) => {  
  let timer = null; 
   return function () {    
    let context = this;    
    let args = arguments;  
      clearTimeout(timer);   
       timer = setTimeout(function () {   
           fn.apply(context, args);  
            }, delay); 
           }} 
           const _ResizeObserver = window.ResizeObserver;
           window.ResizeObserver = class ResizeObserver extends _ResizeObserver{  
            constructor(callback) {    callback = debounce(callback, 16);   
              super(callback); 
              }}

