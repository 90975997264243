<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="visible"
    style="width:340px;height:460px"
  >      
 
  <div class="login-main">
           <span style="font-size:18px;color:#f08047;font-weight: bold;text-align: left;">修改密码</span>
          <el-form style="margin-top: 20px;margin-bottom: 20px;"  label-width="80px"
               :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter="dataFormSubmit()"
                   status-icon>
            <el-form-item   prop="oldpassword"  label="旧密码" >
              <el-input  
              v-model="dataForm.oldpassword" placeholder="旧密码" type="password" autofocus ></el-input>
            </el-form-item>
            <el-form-item prop="password" label="新密码" >
              <el-input  v-model="dataForm.password" type="password" placeholder="新密码"></el-input>
            </el-form-item>
            <el-form-item prop="repassword" label="确认密码" >
              <el-input  v-model="dataForm.repassword" type="password" placeholder="确认密码"></el-input>
            </el-form-item>
              <el-button  type="primary" @click="dataFormSubmitForUpdate()" style=" width: 100%;margin-top: 30px;">
                <span style="font-size:18px;">修 改</span>
              </el-button>
          </el-form>
        </div>
       
  </el-dialog>
</template>

<script>
import {getUsername,getUserId,removeUsername,removeRealUsername,removeUserId } from '@/utils/authentication'
import {ElMessageBox } from 'element-plus'
export default {
 
  data () {
    const validateOldPass = (rule, value, callback) => {
      this.axios({
            url: '/login/validateForInfo.do',
            method: 'post',
            params: {
              'name': getUsername(),
              'pass': value,
            }
          }).then(data => {
            if(data.data.name){
              callback();
            }else{
              return callback(new Error("旧密码不正确！"))
            }
          })
     };
     var repassvalidate = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.dataForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
       }
      }; 

    return {
      visible: false,
      labelPosition:'right',
      dataForm: {
        oldpassword: '',
        password: '',
        repassword:''
      },
      dataRule: {
        oldpassword: [
          {required: true, message: '旧密码不能为空', trigger: 'blur'},
          {validator:validateOldPass, trigger: 'blur'}
        ],
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur'}
        ],
        repassword: [
          {required: true, validator: repassvalidate, trigger: 'blur'}
        ]
      },
    }
  },
  created () {
  },
  mounted(){
  },
  methods: {
    // 初始化对话框
    init(){
     this.visible=true
     this.$nextTick(() => {
            this.$refs.dataForm.resetFields();
          })
    },
    // 提交表单
    dataFormSubmitForUpdate () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.axios({
            url: '/user/updatePassForVue.do',
            method: 'post',
            params: {
              'yhid': getUserId(),
              'pass': this.dataForm.password,
            }
          }).then(data => {
            if(data.data.result=='success'){
              ElMessageBox.alert('密码修改成功，请重新登录！', '提示', {
                confirmButtonText: '确定',
                type:'info',
                callback: (action) => {
                  this.visible=false
                  this.$emit("refreshDataList");
                 }
                 })
          }else{
             
          }
          }).catch(data =>{
            this.$message({
                  message: "密码修改失败，检查网络是否畅通！",
                  type: "success",
                  duration: 1500,
                }); 
          })
        }
      })
    },
    
  }
}
</script>

<style lang="scss">

   .login-main {
      border: 0px solid red;
      border-radius: 5px;
      margin: 0 auto;
      max-width: 260px;
      min-width: 260px;
      height: 360px;
      padding: 7px 10px 10px;
      margin-top: 5px;
      color: #000;
      background-color: #fff;
    }
    .login-btn-submit {
      width: 100%;
      margin-top: 38px;
    }

</style>
