<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="visible"
    style="width:340px;height:460px"
  >      
       <div class="login-main">
           <span style="font-size:18px;color:#f08047;font-weight: bold;text-align: left;">密码登录</span>
          <!-- form -->
          <el-form style="margin-top: 20px;margin-bottom: 20px;"  :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter="dataFormSubmit()"
                   status-icon>
            <el-form-item   prop="userName"   >
              <el-input  style=" width: 100%;margin-top: 20px;" prefix-icon="el-icon-user" v-model="dataForm.userName" placeholder="帐号" autofocus ></el-input>
            </el-form-item>
            <el-form-item prop="password"  >
              <el-input prefix-icon="el-icon-lock" v-model="dataForm.password" type="password" placeholder="密码"></el-input>
            </el-form-item>
            <el-form-item >
              <el-button  type="primary" @click="dataFormSubmit()" style=" width: 100%;margin-top: 30px;">
                <span style="font-size:18px;">登 录</span>
              </el-button>
            </el-form-item>
          </el-form>
          <div v-loading="loading"  element-loading-text="登录中..."></div>
        </div>
  </el-dialog>
</template>

<script>
import { setToken,setUsername,setRealUsername,setUserId,setRole } from '@/utils/authentication'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  data () {
    return {
      loading:false,
      visible: false,
      labelPosition:'right',
      dataForm: {
        userName: '',
        password: '',
        options: [],
        tenantcode:'',
        topathname:''
      },
      dataRule: {
        userName: [
          {required: true, message: '帐号不能为空', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur'}
        ]
      },
    }
  },
  created () {
  },
  mounted(){
  },
  methods: {
    // 初始化对话框
    init(pathname){
      this.topathname=pathname
      this.visible=true
    },
    // 提交表单
    dataFormSubmit () {
      this.loading=true
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.axios({
            url: '/login/loginValidate.do',
            method: 'post',
            params: {
              'name': this.dataForm.userName,
              'pass': this.dataForm.password,
            }
          }).then(data => {
            this.loading=false
            if(data.data.res=='success'){

              this.visible=false
            setUsername(data.data.username)
            setRealUsername(data.data.realusername)
            setUserId(data.data.userid)
            setToken(data.data.token)
            // 设置角色
            setRole(data.data.role)
            //如果有导向路径参数
            if(this.topathname){
              this.$router.push({name:this.topathname})
            }else{
            if(this.$route.path=='/login'){
              //回退
              this.$router.back()
            }else{
             //刷新当前页面
             this.$router.go(0)
            }
           
            }
           
            this.$emit("refreshDataList");
          }else{
            ElMessageBox.alert('账号或密码不对，请联系平台管理员！', '提示', {
                confirmButtonText: '确定',
                type:'warning',
                callback: (action) => {
                 }
                 })
          }
          }).catch(data =>{
          })
        }
      })
    },
    
  }
}
</script>

<style lang="scss">
// 对话框边框的角度
.el-dialog {
  border-radius: 4px !important;
  padding: 0px !important;
}
// 标题颜色
.el-dialog__title {
  color: #ffffff;
  position: relative;
  top:-5px
}
// 修改el-dialog的header背景，上角角度等
.el-dialog__header {
  padding: 10px 10px 10px 10px  !important;
  background-color: #9ca9f8;
  text-align: left;
  border: 1px solid #9ca9f8;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin: 0px  !important;
  height:15px !important;
}

// header上关闭按钮的颜色大小
.el-dialog__headerbtn .el-dialog__close {
  color: #ffffff  !important;
  font-size: 20px  !important;
  margin-bottom: 15px  !important;
  // border:1px solid #cccccc;
}

   .login-main {
      border: 0px solid red;
      border-radius: 5px;
      margin: 0 auto;
      max-width: 260px;
      min-width: 260px;
      height: 360px;
      margin-top: 15px;
      color: #000;
      background-color: #fff;
    }
    .login-btn-submit {
      width: 100%;
      margin-top: 38px;
    }

</style>
