export default {
  namespaced: true,
  state: {
    id: '', //用户id
    name: '',//账号
    realname:'',//真实姓名

  },
  mutations: {
    updateId (state, id) {
      state.id = id
    },
    updateName (state, name) {
      state.name = name
    },
    updateRealName (state, name) {
      state.realname = name
    }

  }
}
