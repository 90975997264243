import { removeToken,removeTenantcode} from "./authentication"
import axios from './httpRequest'
import router from '../router'
/**
 * 去除对象的所有属性的前后空格
 * @param{}obj
 * @returns
 */
export function objPropTrim (obj) {
  if (obj) {
    if (Array.isArray(obj)) {
      obj = obj.map(item => objPropTrim(item))
    } else {
      if (typeof obj === 'string') {
        return obj.trim()
      } else if (typeof obj === 'object') {
        Object.keys(obj).forEach(key => {
          obj[key] = objPropTrim(obj[key])
        })
      }
    }
  }
  return obj
}
/**
 * 随机生成给定长度的字符串
 * @param{*}len
 * @returns
 */
export function randomString (len) {
  len = len || 32
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const maxPos = chars.length
  let pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

/**
 * 深度克隆对象
 * @param{}source
 * @returns
 */
export function deepClone (source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'shallowClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  for (const keys in source) {
    if (source.hasOwnProperty.call(keys)) {
      if (source[keys] && typeof source[keys] === 'object') {
        targetObj[keys] = source[keys].constructor === Array ? [] : {}
        targetObj[keys] = deepClone(source[keys])
      } else {
        targetObj[keys] = source[keys]
      }
    }
  }
  return targetObj
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth (key) {
  return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
}
/**
 * 清空登录信息
 */
export function clearLoginInfo () { 
  removeToken();
  removeTenantcode();
}
export function getUUID () {}

/**
 * 替换html中img标签的url，前面加上axios.baseURL的值
 * @param  html 
 * @returns 
 */
export function replaceImgURL(html){
  const regex = /(<img.*?src=")(.*?)(".*?>)/g; 
 return html.replace(regex,function(a,b,c,d){
    //a是整体
    //b是第一个分组
    //c是第二个分组
    //d是第三个分组
    if(c.startsWith('/homework')){
        
        return b+axios.baseURL+c+d
    }
    return a
})
}
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
//获取给定文件url中文件的名字
export function getFilenmeFromUrl(url){
  const urlObj = new URL(url);
  return urlObj.pathname.split('/').pop();
}
/**
 * 将字符串转成float，不存在为0
 * @param data
 * @returns {number}返回数字类型，可以参与运算
 */
export function parseFloatFromString(data) {
  if(!data || data==null || data=='' )
      data = 0;
  return parseFloat(data);
}

/**
* 将字符串转成float
* @param data，要转换的数据
* @param fixed，保留小数位数，如果没有小数会自动补0
* @returns {string}返回的是字符串类型，不可参与运算，常用在最后结果的换算
*/
export function parseFloatFromStringtoFixed(data,fixed) {
  if(!data || data==null || data=='' )
      data = 0;
  return toDecimal(data,fixed);
}

/**
* 将字符串转成float
* @param data
* @param fixed 保留小数位数，如果没有小数不会自动补0
* @returns {number} 返回数字，可以参与运算
*/
export function parseFloatFromStringtoFixedForNum(data,fixed) {
  if(!data || data==null || data=='' )
      data = 0;
  if(!fixed || fixed==null || fixed==''){
      fixed=2;
  }
  return parseFloat(toDecimal(data,fixed));
}
/**
* 将字符串转化成整数
* @param data
* @returns {number}返回的是数据类型，可以参与算术运算
*/
export function parseIntFromString(data) {
  if(!data || data==null || data=='' )
      data = 0;
  return parseInt(data);
}
/**
*
* @param num，要转换的数据
* @param x 保留小数点位数
* @returns {String}
*/
export function toDecimal(num,x) {
               var result = parseFloat(num);
              if (isNaN(result)) {
                      return 0;
                   }
              result = Math.round(num * Math.pow(10,x)) / Math.pow(10,x);
               var s_x = result.toString(); //将数字转换为字符串
              var pos_decimal = s_x.indexOf('.'); //小数点的索引值
              // 当整数时，pos_decimal=-1 自动补0
               if (pos_decimal < 0) {
                      pos_decimal = s_x.length;
                      s_x += '.';
               }
              // 当数字的长度< 小数点索引+2时，补0
             while (s_x.length <= pos_decimal + 2) {
                      s_x += '0';
                 }
           return s_x;
      }

/**
* value是否在array数组内
* @param array 数组
* @param value
* 在数组内返回true，否则返回false
*/
export function ifInArray(array, value) {
  if (!array || array.length == 0) {
      return false;
  }
  for (var i = 0; i < array.length; i++) {
      if (value == array[i]) {
          return true;
          break;
      }
  }
  return false;
}


/**
* 删除数组中的value元素
* @param array
* @param value
* 返回删除后的数组
*/
export function deletevalueFromArray(array,value) {
  for(var i=0;i<array.length;i++){
      if(array[i] ==value){
          array.splice(i,1);
          i= i-1;//删除后数组实际长度发生了变化，所以i应该减1
      }
  }
  return array;
}

/**
* 返回json字符串中的key对应的值，如果为null返回''
* @param Jstring,json字符串
* @param key，key值
* @returns {string} 返回字符类型
*/
export function getValueFromJSONString(Jstring,key) {
  var jsons = JSON.parse(Jstring);
  return jsons[key]?jsons[key]:'';
}
/**
* 分析两个对象，用data中的数据填充dataForm'
*/
export function analysisDataForm(dataForm,data){
  for (let item in dataForm) {
    if(data[item]){
      dataForm[item]=data[item]
    }
    
  }
}
