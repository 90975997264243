import { createStore } from 'vuex'
import getters from './getters'
import {deepClone} from '@/utils'
/**
 * 读所有modules下的module，存放在mudels常量
 */
 const modulesFiles = require.context('./modules', true, /\.js$/)
 // modules是返回模块对象，modulePath是数组元素的值，ruduce是归纳函数
 const modules = modulesFiles.keys().reduce((modules, modulePath) => {
   const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
   const value = modulesFiles(modulePath)
   modules[moduleName] = value.default
   return modules
 }, {})

const store= createStore({
  modules,
  getters,
  mutations: {
      // 重置vuex本地储存状态
      resetStore (state) {
        Object.keys(state).forEach((key) => {
          state[key] = deepClone(window.SITE_CONFIG['storeState'][key])
        })
      }
  },
  actions: {
  }
})

export default  store
