<template>
  <el-dialog
     title="视频播放"
    :close-on-click-modal="false"
    v-model="visible">
 
  </el-dialog>
</template>

<script>
import {getUsername,getRealUsername,getUserId } from '@/utils/authentication'
  export default {
    data () {
      return {
        visible: false,
        dataForm: {
          id: '',
          title: '',
          description: '',
          titlesource:''
        },
        dataRule: {
          title: [
            {required: true, message: '设计题目不能为空', trigger: 'blur'}
          ],
          titlesource: [
            {required: true, message: '课题来源不能为空', trigger: 'blur'}
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        //重置表单界面
         this.$nextTick(() => {
            // this.$refs['dataForm'].resetFields()
          })
        this.visible = true
          // if (this.dataForm.id) {
          //   this.axios({
          //     url: `/sys/role/getInfoById/`+id,
          //     method: 'post'
          //   }).then(data => {
          //     if (data.data) {
          //       this.dataForm.roleName = data.data.name
          //       this.dataForm.remark = data.data.beizhu
          //     }
          //   })
          // }
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.axios({
              url: `/xuanti/saveZiYOuXuanTiInfo.do`,
              method: 'post',
              params: {
                'title': this.dataForm.title,
                'titlesource': this.dataForm.titlesource,
                description:this.dataForm.description,
                code:getUsername(),
                name:getRealUsername(),
                banji:''
              }
            }).then(data=> {
              if (data.data.result === 'success') {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500
                })
                this.visible = false
                this.$emit('refreshDataList')
              }
            })
          }
        })
      }
    }
  }
</script>
<style lang="scss">

  // 对话框边框的角度
  .el-dialog{
    border-radius: 4px;
  }
  // 对话框footer样式
  .el-dialog__footer{
    height: 50px;
    background-color: #ececf0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  // 修改el-dialog的header背景，上角角度等
 .el-dialog__header{
  padding:20px 20px 10px;
  background-color: #9ca9f8;
  text-align:left;
  border: 1px solid #9ca9f8;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin: 0px;
  }
  // 标题颜色
  .el-dialog__title{
    color: #ffffff;
  }
  // header上关闭按钮的颜色大小
  .el-dialog__headerbtn .el-dialog__close{
    color: #ffffff;
    font-size: 20px;
  }
  

</style>
